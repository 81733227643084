frappe.ui.form.States = class FormStates extends frappe.ui.form.States {
    setup_help() {
        var me = this;
        this.frm.page.add_action_item(
            __("Help"),
            function () {
                frappe.workflow.setup(me.frm.doctype);
                var state = me.get_state();
                var d = new frappe.ui.Dialog({
                    title: __("Workflow") + ": " + __(frappe.workflow.workflows[me.frm.doctype].name),
                });

                frappe.workflow.get_transitions(me.frm.doc).then((transitions) => {
                    const next_actions =
                        $.map(
                            transitions,
                            (d) => `${__(d.action).bold()} ${__("by Role")} ${__(d.allowed)}`
                        ).join(", ") || __("None: End of Workflow").bold();

                    const document_editable_by = frappe.workflow
                        .get_document_state_roles(me.frm.doctype, state)
                        .map((role) => __(role).bold())
                        .join(", ");

                    $(d.body)
                        .html(
                            `
					<p>${__("Current status")}: ${__(state).bold()}</p>
					<p>${__("Document is only editable by users with role")}: ${document_editable_by}</p>
					<p>${__("Next actions")}: ${next_actions}</p>
					<p>${__("{0}: Other permission rules may also apply", [__("Note").bold()])}</p>
				`
                        )
                        .css({ padding: "15px" });

                    d.show();
                });
            },
            true
        );
    }
}
